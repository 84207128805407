import {useDelete, useNotify, useRecordContext, useResourceContext, useUpdate} from "react-admin";
import {FileFormValue, PreparedFormFileValue} from "components/material/view/info/FileAttachmentForm/types";

const useFileHandlers = () => {
  const resource = useResourceContext();
  const record = useRecordContext();
  const notify = useNotify();

  const [deleteFile, {isPending: isDeleting}] = useDelete();
  const [update, {isPending: isUpdating}] = useUpdate();

  const loading = isDeleting || isUpdating;

  const handleUpdateFiles = async (files: FileFormValue[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      files.forEach((file, index) => {
        const formData = new FormData();
        formData.append("name", file.name);
        formData.append("order", index.toString());
        if (file.file.rawFile) {
          formData.append("file", file.file.rawFile);
        }

        update(
          file.id ? `${resource}/${record!.id}/file/${file.id}` : `${resource}/${record!.id}/file`,
          {id: record!.id, data: formData},
          {
            onSuccess: () => {
              console.log("All files updated successfully");
              resolve();
            },
            onError: (error) => {
              console.error(`File update error for file #${index + 1}:`, error);
              notify("Ошибка обновления файла", {type: "error"});
              reject(error);
            },
          }
        );
      });
    });
  };

  const handleDeleteFiles = async (files: PreparedFormFileValue[]): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      files.forEach((file, index) => {
        deleteFile(
          `${resource}/${record!.id}/file`,
          {id: file.id},
          {
            onSuccess: () => {
              console.log("All files deleted successfully");
              resolve();
            },
            onError: (error) => {
              console.error(`File delete error for file #${index + 1}:`, error);
              notify("Ошибка удаления файлов", {type: "error"});
              reject(error);
            },
          }
        );
      });
    });
  };

  const handleSubmit = async (
    data: { files: FileFormValue[] },
    files: PreparedFormFileValue[]
  ): Promise<void> => {
    const updatedFiles = data.files || [];

    const filesToSave: FileFormValue[] = [];
    const filesToDelete: PreparedFormFileValue[] = [];

    const updatedFileIds = new Set(updatedFiles.map((file) => file.id).filter(Boolean));

    files.forEach((file) => {
      if (!updatedFileIds.has(file.id)) {
        filesToDelete.push(file);
      }
    });

    updatedFiles.forEach((file) => {
      filesToSave.push(file);
    });

    try {
      if (filesToSave.length > 0) await handleUpdateFiles(filesToSave);
      if (filesToDelete.length > 0) await handleDeleteFiles(filesToDelete);
      notify("Файлы успешно сохранены", {type: "success"});
    } catch (error) {
      notify("Ошибка при обработке файлов", {type: "error"});
    }
  };


  return {handleSubmit, loading};
};

export default useFileHandlers;
